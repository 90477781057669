import { useEffect, useMemo, useRef } from 'react';
import { AbortFunction, RegisterAbortFunction, AbortType } from '../Utils/AbortUtils';

export default function useAbortRegistry() {
	const registry = useRef<AbortFunction[]>([]);

	const registerCancel = useMemo<RegisterAbortFunction>(
		() => (cancel: AbortFunction) => {
			registry.current.push(cancel);
			return () => {
				registry.current = registry.current.filter((c) => c !== cancel);
			};
		},
		[],
	);

	useEffect(() => {
		return () => {
			registry.current.forEach((cancel) => cancel(AbortType.UNMOUNT));
		};
	}, []);

	return registerCancel;
}
